<template>
  <div class="dialog" @mousedown.self="close">
    <div class="wrapper">
      <div class="dialog-header">
        <p class="title">削除しますか？</p>
        <div class="button" @click="close"><i class="material-icons">close</i></div>
      </div>
      <div class="dialog-content">
        <div class="list">
          <div v-for="(project, i) in selects" :key="i" class="item">
            <div class="name">
              <span class="icon"><i class="material-icons">movie</i></span>
              <span class="text">{{ project.project_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="button cancel" @click="close">キャンセル</div>
        <div class="button" @click="update">削除</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'ProjectDelete',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    const store = useStore()
    const mixins = new Mixins()
    let selects = Object.assign([], store.getters.selectedProjects)
    if (!selects.length) {
      selects = [props.data]
    }
    return {
      store,
      mixins,
      selects,
    }
  },
  data() {
    return {
      editable: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
  },
  methods: {
    async update() {
      const res = await this.mixins.deleteproject(this.selects)
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
  },
})
</script>

<style lang="stylus" scoped>
.dialog
  .wrapper
    width 800px
    height auto

.list
  height 250px
  border-top 1px solid #bababa
  border-bottom 1px solid #bababa
  background-color #F7F7F7
  overflow auto
  .item
    display grid
    grid-template minmax(50px, auto) / 1fr auto
    align-items center
    padding .2em .5em
    font-size .8rem
    line-height 1.5
    border-bottom 1px solid #E3E3E3
    .name
      display grid
      grid-template auto / auto 1fr
      grid-gap .5em
      align-items center
      padding 0 .3em
      border-radius 2px
      line-height 1.8
      .text
        ellipsis()
//
</style>
