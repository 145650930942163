<template>
  <dialog-share v-if="dialog.method === 'share'" :data="dialog.data" @close="close" @update="update"></dialog-share>
  <dialog-edit v-else-if="dialog.method === 'edit'" :data="dialog.data" @close="close" @update="update"></dialog-edit>
  <dialog-delete v-else-if="dialog.method === 'delete'" :data="dialog.data" @close="close" @update="update"></dialog-delete>
</template>

<script>
import { defineComponent } from 'vue'
import Mixins from '@/mixins/mixins'

// Dialog
import DialogShare from '@/components/dialog/ProjectShare.vue'
import DialogEdit from '@/components/dialog/ProjectEdit.vue'
import DialogDelete from '@/components/dialog/ProjectDelete.vue'

export default defineComponent({
  name: 'DialogsFolder',
  components: {
    'dialog-share': DialogShare,
    'dialog-edit': DialogEdit,
    'dialog-delete': DialogDelete,
  },
  props: ['dialog'],
  setup() {
    const mixins = new Mixins()
    return {
      mixins,
    }
  },
  data() {
    return {
      editable: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    close() {
      this.$emit('close')
    },
    update() {
      this.$emit('update')
    },
  },
})
</script>

<style lang="stylus" scoped>
</style>
