
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import DialogsProject from '@/components/dialog/DialogsProject.vue'
export default defineComponent({
  name: 'ProjectFinder',
  components: {
    'dialogs-project': DialogsProject,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()

    // グルグルをクリア
    store.commit('clearPending')

    // 検索クエリのクリア
    store.commit('setFiltersProjectQuery', {
      name: '',
    })
    store.commit('setFiltersSozaiQuery', {
      name: '',
      speech: '',
      cast: '',
    })
    store.commit('setFiltersWatchlistQuery', {
      name: '',
    })

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      isPowerUser,
      isSuperUser,
    }
  },
  data(): {
    list: any
    context: any
    dialog: any
    ui: any
  } {
    return {
      list: null,
      context: null,
      dialog: {
        type: null,
        method: null,
        data: null,
      },
      ui: {
        add: false,
        sort: {
          key: 'project_name', // ソートキー
          ascend: true, // true: 昇順, false: 降順
        },
        lastSelected: null,
      },
    }
  },
  computed: {
    list_(): any {
      let list_: any
      if (this.list) {
        list_ = this.list.filter((project: any) => {
          project.select = false
          if (project.project_name) {
            return this.mixins.matchRegExp(project.project_name, this.projectQuery.name)
          } else {
            return false
          }
        })
      } else {
        list_ = []
      }
      // ソート
      list_.sort((a: any, b: any) => {
        if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
          switch (this.ui.sort.key) {
            case 'created_at': {
              const a_ = new Date(a[this.ui.sort.key]).getTime()
              const b_ = new Date(b[this.ui.sort.key]).getTime()
              return a_ - b_
            }
            default: {
              return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
            }
          }
        } else {
          return -1
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        list_.reverse()
      }
      return list_
    },
    projectQuery: {
      get(): any {
        return this.store.getters.filtersProjectQuery
      },
      set(query): any {
        this.store.commit('setFiltersProjectQuery', query)
      },
    },
    selectedProjects: {
      get(): any {
        return this.store.getters.selectedProjects
      },
      set(query): any {
        this.store.commit('setSelectedProjects', query)
      },
    },
  },
  watch: {
    'ui.add': function () {
      if (this.ui.add) {
        console.log('true')
        window.addEventListener('click', this.closeAddMenu)
      } else {
        console.log('false')
        window.removeEventListener('click', this.closeAddMenu)
      }
    },
  },
  async beforeMount() {
    // setup()内でasync/awaitを使うにはonMountで書く必要がある
    // 面倒なのでとりあえずこっちで行う
    this.refresh()
  },
  methods: {
    // ダイアログ系
    // プロジェクトの追加
    openAdd() {
      this.dialog = {
        type: 'project',
        method: 'edit',
        data: {},
      }
    },
    openEdit() {
      this.dialog = {
        type: this.context.type,
        method: 'edit',
        data: this.context.item,
      }
    },
    openShare() {
      this.dialog = {
        type: this.context.type,
        method: 'share',
        data: this.context.item,
      }
    },
    openDelete() {
      this.dialog = {
        type: this.context.type,
        method: 'delete',
        data: this.context.item,
      }
    },
    closeDialog() {
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
    },
    closeAddMenu() {
      this.ui.add = false
    },
    // プロジェクトを開く
    clickProject(item: any) {
      this.router.push(`/project/${item.project_id}/`)
    },
    selectItem(e: any, item: any, index: any) {
      // 変数
      let from: any = null
      let count: any = null
      // シフトキーが押されている
      if (e.shiftKey) {
        // 前クリックがある、選択されているものがある
        if (this.ui.lastSelected !== null && this.selectedProjects.length) {
          const diff = index - this.ui.lastSelected
          if (Math.sign(diff) > 0) {
            from = this.ui.lastSelected
            count = diff
          } else {
            from = index
            count = diff * -1
          }
          for (let i = from; i <= from + count; i++) {
            this.list_[i].select = true
          }
        } else {
          item.select = !item.select
        }
      } else {
        const select_ = item.select
        // メタキーが押されていない
        if (!e.ctrlKey && !e.metaKey) {
          // 選択状態を解除
          this.list_.forEach((item: any, i: any) => {
            item.select = false
          })
        }
        if ((!e.ctrlKey && !e.metaKey) && this.selectedProjects.length > 1 && select_) {
          item.select = true
        } else {
          item.select = !select_
        }
      }
      this.ui.lastSelected = index
      this.selectedProjects = this.list.filter((item: any) => item.select)
      if (!this.selectedProjects.length) {
        this.ui.lastSelected = null
      }
      document.getSelection()?.empty()
    },
    // コンテキストメニュー系
    openContextMenu(e: any, item: any, index: any) {
      // 自身の選択状態で判定
      if (!item.select) {
        // 選択状態を解除
        this.list_.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = true
      this.selectedProjects = this.list.filter((item: any) => item.select)
      // this.selectItem(e, item, index)
      this.context = {
        type: 'project',
        item: item,
        position: { x: e.clientX, y: e.clientY },
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return false }
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return true }
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      const wb = window.innerHeight
      // console.log(x, y)
      return { left: `${x}px`, top: `${wb - y > 230 ? y - 10 : wb - 240}px` }
    },
    // ソート指定
    sort(key: string) {
      if (key === this.ui.sort.key) {
        this.ui.sort.ascend = !this.ui.sort.ascend
      } else {
        this.ui.sort.key = key
        this.ui.sort.ascend = true
      }
    },
    async onResearch() {
      const project = await this.mixins.getProject({
        project_id: this.context.item.project_id,
      })
      const res = await this.mixins.research(project.sources)
      if (res) {
        this.refresh()
      } else {
        console.log('エラー')
      }
    },
    async refresh() {
      // console.log('refresh')
      this.list = await this.mixins.getListProject()
      document.title = `マイプロジェクト｜KAOTAN`
      this.closeDialog()
    },
  },
})
