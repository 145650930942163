<template>
  <div class="main finder">
    <!-- 絞り込み -->
    <div class="box-filter">
      <div class="filter">
        <div class="filter-header">
          <div class="title">
            <span class="icon"><i class="material-icons">search</i></span>
            <span class="text">プロジェクトを検索</span>
          </div>
        </div>
        <div class="filter-body">
          <div class="input text" :class="{on: projectQuery.name}">
            <input type="text" v-model="projectQuery.name" placeholder="プロジェクト名から探す">
            <div class="button" @click="projectQuery.name = ''"><i class="material-icons">cancel</i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- リスト -->
    <div class="box-list">
      <!-- リスト上部のUI -->
      <div class="box-header">
        <div class="location">
          <div class="path">
            <div class="icon"><i class="material-icons">movie</i></div>
            <div class="text">プロジェクト一覧</div>
          </div>
        </div>
      </div>
      <!-- リスト本体 -->
      <div class="box-content">
        <div class="content-header">
          <div class="listheadings">
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'project_name'}" @click="sort('project_name')">
                <span class="text">名前</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'user_id'}" @click="sort('user_id')">
                <span class="text">作成者</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
            <div class="column">
              <div class="key" :class="{on: ui.sort.key === 'created_at'}" @click="sort('created_at')">
                <span class="text">作成日</span>
                <span class="icon" v-if="ui.sort.ascend"><i class="material-icons">arrow_upward</i></span>
                <span class="icon" v-else><i class="material-icons">arrow_downward</i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body">
          <!-- ログインユーザーが閲覧・編集できるプロジェクト一覧 -->
          <div v-if="list" class="list projectList" id="projectList">
            <div
              class="item project"
              v-for="(item, i) in list_"
              :key="i"
              :class="{on: item.select}"
              @click="selectItem($event, item, i)"
              @dblclick="clickProject(item)"
              @contextmenu.prevent="openContextMenu($event, item, i)"
            >
              <div class="column">
                <div class="name">
                  <div class="icon"><i class="material-icons">movie</i></div>
                  <div class="text">{{ item.project_name }}</div>
                </div>
              </div>
              <div class="column">
                <div class="owner">
                  <span class="text">{{ item.user_id }}</span>
                </div>
              </div>
              <div class="column">
                <div class="owner">
                  <span class="text">{{ mixins.getFormatedDate(item.created_at) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <p>プロジェクトがありません</p>
          </div>
        </div>
        <div class="ui">
          <div class="count">{{ list_.length }}件</div>
          <div class="add" :class="{on: ui.add}">
            <div class="button add" @click="openAdd">
              <span class="icon material-icons">add</span>
            </div>
          </div>
          <div class="context" v-if="context" :style="contextStyle()">
            <div class="row" v-if="selectedProjects.length <= 1 && (context.item.user_type !== 2 || isSuperUser)"><div class="button" @click="openShare"><i class="material-icons">person_add</i><span class="text">共有</span></div></div>
            <div class="row" v-if="selectedProjects.length <= 1 && (context.item.user_type !== 2 || isSuperUser)"><div class="button" @click.stop="onResearch"><i class="material-icons">find_replace</i><span class="text">再解析</span></div></div>
            <div class="row" v-if="selectedProjects.length <= 1 && (context.item.user_type !== 2 || isSuperUser)"><div class="button" @click="openEdit"><i class="material-icons">edit</i><span class="text">名前を変更</span></div></div>
            <div class="row" v-if="context.item.user_type !== 2 || isSuperUser"><div class="button" @click="openDelete"><i class="material-icons">delete</i><span class="text">削除</span></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dialogs-project v-if="dialog.type === 'project'" :dialog="dialog" @close="closeDialog" @update="refresh"></dialogs-project>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
import DialogsProject from '@/components/dialog/DialogsProject.vue'
export default defineComponent({
  name: 'ProjectFinder',
  components: {
    'dialogs-project': DialogsProject,
  },
  setup() {
    // Vue3のCompositAPIでの取得方法
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const mixins = new Mixins()

    // グルグルをクリア
    store.commit('clearPending')

    // 検索クエリのクリア
    store.commit('setFiltersProjectQuery', {
      name: '',
    })
    store.commit('setFiltersSozaiQuery', {
      name: '',
      speech: '',
      cast: '',
    })
    store.commit('setFiltersWatchlistQuery', {
      name: '',
    })

    // 権限をセット
    const isPowerUser = store.getters.isPowerUser
    const isSuperUser = store.getters.isSuperUser

    return {
      route,
      router,
      store,
      mixins,
      isPowerUser,
      isSuperUser,
    }
  },
  data(): {
    list: any
    context: any
    dialog: any
    ui: any
  } {
    return {
      list: null,
      context: null,
      dialog: {
        type: null,
        method: null,
        data: null,
      },
      ui: {
        add: false,
        sort: {
          key: 'project_name', // ソートキー
          ascend: true, // true: 昇順, false: 降順
        },
        lastSelected: null,
      },
    }
  },
  computed: {
    list_(): any {
      let list_: any
      if (this.list) {
        list_ = this.list.filter((project: any) => {
          project.select = false
          if (project.project_name) {
            return this.mixins.matchRegExp(project.project_name, this.projectQuery.name)
          } else {
            return false
          }
        })
      } else {
        list_ = []
      }
      // ソート
      list_.sort((a: any, b: any) => {
        if (a[this.ui.sort.key] && b[this.ui.sort.key]) {
          switch (this.ui.sort.key) {
            case 'created_at': {
              const a_ = new Date(a[this.ui.sort.key]).getTime()
              const b_ = new Date(b[this.ui.sort.key]).getTime()
              return a_ - b_
            }
            default: {
              return a[this.ui.sort.key].localeCompare(b[this.ui.sort.key], 'ja')
            }
          }
        } else {
          return -1
        }
      })
      // 昇順・降順
      if (!this.ui.sort.ascend) {
        list_.reverse()
      }
      return list_
    },
    projectQuery: {
      get(): any {
        return this.store.getters.filtersProjectQuery
      },
      set(query): any {
        this.store.commit('setFiltersProjectQuery', query)
      },
    },
    selectedProjects: {
      get(): any {
        return this.store.getters.selectedProjects
      },
      set(query): any {
        this.store.commit('setSelectedProjects', query)
      },
    },
  },
  watch: {
    'ui.add': function () {
      if (this.ui.add) {
        console.log('true')
        window.addEventListener('click', this.closeAddMenu)
      } else {
        console.log('false')
        window.removeEventListener('click', this.closeAddMenu)
      }
    },
  },
  async beforeMount() {
    // setup()内でasync/awaitを使うにはonMountで書く必要がある
    // 面倒なのでとりあえずこっちで行う
    this.refresh()
  },
  methods: {
    // ダイアログ系
    // プロジェクトの追加
    openAdd() {
      this.dialog = {
        type: 'project',
        method: 'edit',
        data: {},
      }
    },
    openEdit() {
      this.dialog = {
        type: this.context.type,
        method: 'edit',
        data: this.context.item,
      }
    },
    openShare() {
      this.dialog = {
        type: this.context.type,
        method: 'share',
        data: this.context.item,
      }
    },
    openDelete() {
      this.dialog = {
        type: this.context.type,
        method: 'delete',
        data: this.context.item,
      }
    },
    closeDialog() {
      this.dialog = {
        type: null,
        method: null,
        data: null,
      }
    },
    closeAddMenu() {
      this.ui.add = false
    },
    // プロジェクトを開く
    clickProject(item: any) {
      this.router.push(`/project/${item.project_id}/`)
    },
    selectItem(e: any, item: any, index: any) {
      // 変数
      let from: any = null
      let count: any = null
      // シフトキーが押されている
      if (e.shiftKey) {
        // 前クリックがある、選択されているものがある
        if (this.ui.lastSelected !== null && this.selectedProjects.length) {
          const diff = index - this.ui.lastSelected
          if (Math.sign(diff) > 0) {
            from = this.ui.lastSelected
            count = diff
          } else {
            from = index
            count = diff * -1
          }
          for (let i = from; i <= from + count; i++) {
            this.list_[i].select = true
          }
        } else {
          item.select = !item.select
        }
      } else {
        const select_ = item.select
        // メタキーが押されていない
        if (!e.ctrlKey && !e.metaKey) {
          // 選択状態を解除
          this.list_.forEach((item: any, i: any) => {
            item.select = false
          })
        }
        if ((!e.ctrlKey && !e.metaKey) && this.selectedProjects.length > 1 && select_) {
          item.select = true
        } else {
          item.select = !select_
        }
      }
      this.ui.lastSelected = index
      this.selectedProjects = this.list.filter((item: any) => item.select)
      if (!this.selectedProjects.length) {
        this.ui.lastSelected = null
      }
      document.getSelection()?.empty()
    },
    // コンテキストメニュー系
    openContextMenu(e: any, item: any, index: any) {
      // 自身の選択状態で判定
      if (!item.select) {
        // 選択状態を解除
        this.list_.forEach((item: any, i: any) => {
          item.select = false
        })
      }
      item.select = true
      this.selectedProjects = this.list.filter((item: any) => item.select)
      // this.selectItem(e, item, index)
      this.context = {
        type: 'project',
        item: item,
        position: { x: e.clientX, y: e.clientY },
      }
      window.addEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return false }
      // console.log(this.context)
    },
    closeContextMenu(e: any) {
      e.preventDefault()
      this.context = null
      window.removeEventListener('click', this.closeContextMenu)
      document.oncontextmenu = function () { return true }
      // console.log(this.context)
    },
    contextStyle() {
      const x = this.context ? this.context.position.x : 0
      const y = this.context ? this.context.position.y : 0
      const wb = window.innerHeight
      // console.log(x, y)
      return { left: `${x}px`, top: `${wb - y > 230 ? y - 10 : wb - 240}px` }
    },
    // ソート指定
    sort(key: string) {
      if (key === this.ui.sort.key) {
        this.ui.sort.ascend = !this.ui.sort.ascend
      } else {
        this.ui.sort.key = key
        this.ui.sort.ascend = true
      }
    },
    async onResearch() {
      const project = await this.mixins.getProject({
        project_id: this.context.item.project_id,
      })
      const res = await this.mixins.research(project.sources)
      if (res) {
        this.refresh()
      } else {
        console.log('エラー')
      }
    },
    async refresh() {
      // console.log('refresh')
      this.list = await this.mixins.getListProject()
      document.title = `マイプロジェクト｜KAOTAN`
      this.closeDialog()
    },
  },
})
</script>

<style lang="stylus" scoped>
.content-header
  display grid
  grid-template auto / auto
  grid-gap 2px
  .listheadings
    display grid
    grid-template minmax(40px, auto) / 2fr 1fr 1fr
    align-items center
    grid-gap $gap
    padding 0 $gap * 2
    font-size .7rem
    border-bottom 1px solid alpha(#fff, .2)
    .column
      .key
        display grid
        grid-template auto \/ auto auto
        grid-gap 4px
        align-items center
        justify-content flex-start
        width fit-content
        cursor pointer
        .text
          ellipsis()
        .icon
          display none
          i
            font-size 20px
        &.on
          .icon
            display inline-flex

//
.content-body
  height 100%
  overflow auto
//
.list:not(.box) // class名はあとで考える
  font-size .7rem
  margin-bottom 60px
  &:last-child
    border-bottom 1px solid alpha(#fff, .2)

  &.projectList
    .item
      display grid
      grid-template minmax(50px, auto) / 2fr 1fr 1fr
      align-items center
      grid-gap $gap
      padding 0 $gap * 2
      font-size .7rem
      cursor pointer
      & + .item
        border-top 1px solid alpha(#fff, .2)

      .column
        overflow hidden
      .name
        display grid
        grid-template auto / auto 1fr
        grid-gap .5em
        align-items center
        width fit-content
        max-width 100%
        cursor pointer
        .text
          line-height 1.4
          ellipsis()
        &:hover
          .text
            white-space unset
            // overflow auto
      .owner
        ellipsis()

      &:hover
        background-color #1C1C1C
      &.on
        background-color $color-active

</style>
