
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Mixins from '@/mixins/mixins'
export default defineComponent({
  name: 'ProjectDelete',
  components: {
    // 'dialog-announce': DialogAnnounce,
  },
  props: ['data'],
  setup(props) {
    const store = useStore()
    const mixins = new Mixins()
    let selects = Object.assign([], store.getters.selectedProjects)
    if (!selects.length) {
      selects = [props.data]
    }
    return {
      store,
      mixins,
      selects,
    }
  },
  data() {
    return {
      editable: null,
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeMount() {
    this.editable = this.mixins.getDuplicateObject(this.data)
  },
  methods: {
    async update() {
      const res = await this.mixins.deleteproject(this.selects)
      if (res) {
        this.$emit('update')
      }
    },
    close() {
      this.$emit('close')
    },
  },
})
